import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import type { AnswersType, QuestionsType } from "@ScreenerSetup/types";

type metaType = {
  id: string;
  variant?: string;
  version?: string;
  history: QuestionsType[];
  currentStep: QuestionsType;
  isComplete?: boolean;
  isRecorded?: boolean;
};
export type screenerValuesType = Record<QuestionsType, AnswersType | number | string>;

export type screenerStateType = {
  [key: string]: {
    values: screenerValuesType;
    meta: metaType;
  };
};

const initialState: screenerStateType = {};

export const screenerSlice = createSlice({
  initialState,
  name: "screener",
  reducers: {
    answerQuestion: (
      state: screenerStateType,
      action: PayloadAction<{
        screenerKey: string;
        value: AnswersType;
        isFinal: boolean;
        question: QuestionsType;
        historyKey: string;
      }>,
    ) => {
      const { screenerKey, value, isFinal, question, historyKey } = action.payload;
      const isStepInHistory = state[screenerKey].meta.history.includes(historyKey);
      return {
        ...state,
        [screenerKey]: {
          meta: {
            ...state[screenerKey].meta,
            history: isStepInHistory
              ? [...state[screenerKey].meta.history]
              : [...state[screenerKey].meta.history, historyKey],
            isComplete: isFinal,
          },
          values: { ...state[screenerKey].values, [question]: value },
        },
      };
    },
    back: (state: screenerStateType, action: PayloadAction<{ screenerKey: string }>) => {
      const historyCopy = [...state[action.payload.screenerKey].meta.history];

      return {
        ...state,
        [action.payload.screenerKey]: {
          meta: {
            ...state[action.payload.screenerKey].meta,
            currentStep: historyCopy[historyCopy.length - 1],
            history: historyCopy.slice(0, -1),
          },
          values: { ...state[action.payload.screenerKey].values },
        },
      };
    },

    initScreener: (state: screenerStateType, action: PayloadAction<{ screenerKey: string; meta: metaType }>) => {
      const { screenerKey, meta } = action.payload;
      return {
        ...state,
        [screenerKey]: {
          meta: {
            ...meta,
            history: [],
            isComplete: false,
          },
          values: {},
        },
      };
    },
    record: (state: screenerStateType, action: PayloadAction<{ screenerKey: string }>) => {
      return {
        ...state,
        [action.payload.screenerKey]: {
          ...state[action.payload.screenerKey],
          meta: {
            ...state[action.payload.screenerKey].meta,
            isRecorded: true,
          },
        },
      };
    },
    setCurrentStep: (
      state: screenerStateType,
      action: PayloadAction<{ screenerKey: string; currentStep: QuestionsType }>,
    ) => {
      const { screenerKey, currentStep } = action.payload;

      return {
        ...state,
        [screenerKey]: {
          meta: {
            ...state[screenerKey].meta,
            currentStep: currentStep,
          },
          values: { ...state[screenerKey].values },
        },
      };
    },
  },
});

export const { answerQuestion, initScreener, setCurrentStep, back, record } = screenerSlice.actions;

export default screenerSlice.reducer;
