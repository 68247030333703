import getConfig from "next/config";
import Bugsnag from "@bugsnag/js";
import { type Event } from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();

const sharedConfig = {
  enabledReleaseStages: ["production", "review", "uat"],
  onError: (e: Event) => {
    const error = e?.originalError;

    e.addMetadata("response", {
      apiMessage: error?.response?.data?.msg,
      code: error?.code,
      data: error?.config?.data,
      message: error?.message,
      method: error?.config?.method,
      params: error?.config?.params,
      query: error?.request?.query,
      status: error?.response?.status,
      url: error?.config?.url,
    });
  },
  releaseStage: publicRuntimeConfig.ENVIRONMENT,
};

if (typeof window === "undefined") {
  if (!Bugsnag.isStarted()) {
    Bugsnag.start({
      ...sharedConfig,
      apiKey: serverRuntimeConfig.BUGSNAG_API_KEY,
      appVersion: global?.__NEXT_DATA__?.buildId,
      plugins: [new BugsnagPluginReact()],
    });
  }
} else {
  if (!Bugsnag.isStarted()) {
    Bugsnag.start({
      ...sharedConfig,
      apiKey: publicRuntimeConfig.BUGSNAG_API_KEY,
      appVersion: window?.__NEXT_DATA__?.buildId,
      plugins: [new BugsnagPluginReact()],
    });
  }
}

export default Bugsnag;
